import { FC } from 'react'
import { Header, NavbarProps, Navigation } from 'ui'
import { HeaderWrapper } from 'ui/organism/Header'
import { Logo } from '@components/icons/Logo'

const Navbar: FC<NavbarProps> = ({ headerLinkGroups }) => {
	return (
		<HeaderWrapper>
			<Header customLogo={<Logo />}>
				<Navigation headerLinkGroups={headerLinkGroups} />
			</Header>
		</HeaderWrapper>
	)
}

export default Navbar
