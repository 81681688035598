import { ComponentType, Fragment, ReactNode } from 'react'
import { AbstractButton, Container, NavLink } from '../../atoms'
import {
	LinkColumn,
	LinkGroup,
	SimpleLink,
} from '../Navigation/Navigation.types'
import { Accordion } from '../Accordion'

interface FooterProps {
	mainLinks?: Array<LinkColumn>
	bottomLinks?: Array<SimpleLink>
	contactUs?: Array<string>
	children: ReactNode
	linkComponent?: ComponentType<any>
}

export const Footer = ({
	mainLinks,
	bottomLinks,
	contactUs,
	children,
	linkComponent: LinkComponent = AbstractButton,
}: FooterProps) => {
	function generateTitles(title: string) {
		return <p className="uppercase_title_medium text-gray-55">{title}</p>
	}

	function generateLinks(links: Array<SimpleLink>) {
		return (
			links.length !== 0 && (
				<ul className="mt-4 sm:mt-0">
					{links.map((link: any, i) => {
						return (
							<li key={i} className="mt-2 flex gap-x-1 first:mt-0">
								<NavLink
									href={link.url}
									tag={link.tag}
									className="sm:-mx-4 sm:!px-0"
								>
									{link.text}
								</NavLink>
							</li>
						)
					})}
				</ul>
			)
		)
	}

	function generateColumn(linkGroups: Array<LinkGroup>) {
		return (
			<div
				key={`column-${linkGroups[0]?.title}`}
				className="flex w-full flex-col gap-y-4 sm:gap-y-0"
			>
				{linkGroups.map(({ title, links }) => generateLinkGroup(title, links))}
			</div>
		)
	}

	function generateLinkGroup(title: string, links: any) {
		return (
			<Fragment key={title}>
				<Accordion
					title={title}
					titleStyle="uppercase_title_large"
					className="text-gray-55 w-full md:hidden lg:hidden"
				>
					<div>{generateLinks(links)}</div>
				</Accordion>
				<div className="w-full sm:hidden">
					{generateTitles(title)}
					{generateLinks(links)}
				</div>
			</Fragment>
		)
	}

	const generateContactUs = () => {
		return (
			<ul className="mt-8 sm:mt-6">
				{contactUs?.map((item: string, idx: number) => {
					return (
						<li key={`${item}-${idx}`}>
							<p className="p_highlight text-secondary">{item}</p>
						</li>
					)
				})}
			</ul>
		)
	}

	const currentYear = new Date().getFullYear()

	return (
		<div className="z-footer relative">
			{Array.isArray(mainLinks) && mainLinks.length > 0 && (
				<footer className="bg-gray-white sm:py-14 md:px-6 md:py-12 lg:py-14">
					<Container>
						<div className="flex w-full justify-between sm:flex-col md:gap-x-6 lg:gap-x-14">
							{mainLinks.map(({ groups }) => generateColumn(groups))}
						</div>
					</Container>
				</footer>
			)}
			<footer className="bg-primary text-white sm:py-14 md:px-6 md:py-12 lg:py-14">
				<Container>
					<div className="flex justify-between sm:flex-col-reverse sm:items-center sm:text-center">
						<div className="order-1 w-[328px] sm:mt-4 sm:w-full">
							<ul>
								{bottomLinks?.map(({ text, url }: SimpleLink, idx: number) => {
									return (
										<li className="mt-2 w-fit first:mt-0 sm:mx-auto" key={idx}>
											<LinkComponent href={url} className="text-white">
												<p className="p_highlight">{text}</p>
											</LinkComponent>
										</li>
									)
								})}
							</ul>
							{contactUs && (
								<div className="flex flex-col">{generateContactUs()}</div>
							)}
							<div className="mt-6 w-fit sm:mx-auto">
								<LinkComponent href="/" className="text-secondary">
									<p className="p_highlight">
										© {currentYear} Trenkwalder Group
									</p>
								</LinkComponent>
							</div>
						</div>
						<div className="order-2 flex flex-col sm:order-1 sm:w-full">
							{children}
						</div>
					</div>
				</Container>
			</footer>
		</div>
	)
}
