import { useCallback } from 'react'
import { useRouter } from 'next/router'

export const useLanguageSwitch = () => {
	const { pathname, asPath, query, locale, push } = useRouter()

	const handleLanguageChange = useCallback(
		(locale: string) => {
			push({ pathname, query }, asPath, { locale })
		},
		[pathname, asPath, query, push]
	)

	return {
		onLanguageChange: handleLanguageChange,
		locale,
	}
}
